export const APP_DOWNLOAD_URL = {
  ANDROID: "https://play.google.com/store/apps/details?id=com.aircrex",
  IOS: "https://apps.apple.com/au/app/hello-clever/id1499192377",
};

export const SOCIAL_URL = {
  FACEBOOK: "https://www.facebook.com/helloclever.co",
  X: "https://x.com/hello_clever",
  INSTAGRAM: "https://www.instagram.com/helloclever.co/",
  LINKEDIN: "https://www.linkedin.com/company/helloclever",
  TIKTOK: "https://www.tiktok.com/@helloclever.co",
  YOUTUBE: "https://www.youtube.com/@hello_clever",
};

export const ADDRESS = "https://maps.app.goo.gl/xLUQ9d6qrBuuYp4a7";

export const MERCHANT_SIGN_UP = "https://merchant.helloclever.co";
export const OUR_PARTNERS =
  "https://www.notion.so/helloclever/Our-Partners-ace5b327b05e4c09b77dfb2b5dc9a475";
export const CAREERS =
  "https://helloclever.notion.site/Careers-Hello-Clever-119c8c68c1c54eb5b858202ef8a1cccf";
export const FAQS = "https://faqs.helloclever.co/en";
export const DEVELOPER_PORTAL = "https://docs.helloclever.co/docs/";
export const DEVELOPER_API_REF = "https://docs.helloclever.co/api-reference/";
export const DEMO_FORM =
  "https://share.hsforms.com/11wjC2b6zSRW_QpMl7k3njw4obho";

export const MERCHANT_SUPPORT =
  "https://support.helloclever.co/merchant-knowledge-base";

export const CONSUMER_SUPPORT =
  "https://support.helloclever.co/consumer-knowledge-base/faqs";

export const LAUNTEL_VIDEO_URL = "https://youtu.be/0HXyyY-tQGw";
export const CAKE_CREATION_URL =
  "https://youtu.be/i8e0jF5JIs4?si=9H-HmmtoL8MIXL89";

export const CLEVER_2023_REVIEW_BLOG_SLUG =
  "our-2023-wraps-a-year-of-innovation-success-and-exciting-new-features";

export const LOTTIE_URLS: any = {
  ALTERNATIVE:
    "https://aircrexlandingpageprod.azureedge.net/static/assets/animation/alternative.json",
  AUTHORISATION_REQUEST:
    "https://aircrexlandingpageprod.azureedge.net/static/assets/animation/authorisation_request.json",
  BENEFITS:
    "https://aircrexlandingpageprod.azureedge.net/static/assets/animation/benefits.json",
  BUY_TO_EARN:
    "https://aircrexlandingpageprod.azureedge.net/static/assets/animation/buy-to-earn-tab.json",
  CARD_TAB:
    "https://aircrexlandingpageprod.azureedge.net/static/assets/animation/card-tab.json",
  CASHBACK:
    "https://aircrexlandingpageprod.azureedge.net/static/assets/animation/cashback.json",
  DEVELOP_TEAM:
    "https://aircrexlandingpageprod.azureedge.net/static/assets/animation/develop-team.json",
  DISCOVER:
    "https://aircrexlandingpageprod.azureedge.net/static/assets/animation/discover.json",
  EASY_BUDGETING_CUT:
    "https://aircrexlandingpageprod.azureedge.net/static/assets/animation/easy-budgeting-cut.json",
  FAQ: "https://aircrexlandingpageprod.azureedge.net/static/assets/animation/faq.json",
  FIND_US_ONE:
    "https://aircrexlandingpageprod.azureedge.net/static/assets/animation/find_us_one.json",
  FIND_US_TWO:
    "https://aircrexlandingpageprod.azureedge.net/static/assets/animation/find_us_two.json",
  GET_PAID:
    "https://aircrexlandingpageprod.azureedge.net/static/assets/animation/get_paid.json",
  INCREASE_BASKET:
    "https://aircrexlandingpageprod.azureedge.net/static/assets/animation/increase-basket.json",
  INDUSTRIES_BILLER:
    "https://aircrexlandingpageprod.azureedge.net/static/assets/animation/industries_biller.json",
  INDUSTRIES_E_COMMERCE:
    "https://aircrexlandingpageprod.azureedge.net/static/assets/animation/industries_e-commerce.json",
  INDUSTRIES_GAMING_AND_CRYPTO:
    "https://aircrexlandingpageprod.azureedge.net/static/assets/animation/industries_gaming_and_crypto.json",
  INDUSTRIES_SME:
    "https://aircrexlandingpageprod.azureedge.net/static/assets/animation/industries_sme.json",
  INDUSTRIES_TELCO:
    "https://aircrexlandingpageprod.azureedge.net/static/assets/animation/industries_telco.json",
  INSTANT_CUSTOMER:
    "https://aircrexlandingpageprod.azureedge.net/static/assets/animation/instant_customer.json",
  // INSTANT_FLASH:
  //   "https://aircrexlandingpageprod.azureedge.net/static/assets/animation/instant-flash.json",
  MARKETING_ASSETS:
    "https://aircrexlandingpageprod.azureedge.net/static/assets/animation/marketing-assets.json",
  MERCHANT_DISCOVER:
    "https://aircrexlandingpageprod.azureedge.net/static/assets/animation/merchant-discover.json",
  ONE_CLICK_CHECKOUT:
    "https://aircrexlandingpageprod.azureedge.net/static/assets/animation/one_click_checkout.json",
  // PAGE_LOADING:
  //   "https://aircrexlandingpageprod.azureedge.net/static/assets/animation/page_loading.json",
  PAY_AND_GET_PAID:
    "https://aircrexlandingpageprod.azureedge.net/static/assets/animation/pay_and_get_paid.json",
  PAY_WITH_PAYTO:
    "https://aircrexlandingpageprod.azureedge.net/static/assets/animation/pay_with_payto.json",
  PAYID_TAB:
    "https://aircrexlandingpageprod.azureedge.net/static/assets/animation/payid-tab.json",
  PAYTO_INSTANT:
    "https://aircrexlandingpageprod.azureedge.net/static/assets/animation/payto_instant_customer.json",
  PAYTO_TAB:
    "https://aircrexlandingpageprod.azureedge.net/static/assets/animation/payto-tab.json",
  REACH_NEW_CUSTOMER:
    "https://aircrexlandingpageprod.azureedge.net/static/assets/animation/reach-new-customer.json",
  REAL_TIME:
    "https://aircrexlandingpageprod.azureedge.net/static/assets/animation/real-time.json",
  REDUCE_FRAUD:
    "https://aircrexlandingpageprod.azureedge.net/static/assets/animation/reduce_fraud.json",
  REDUCE_CART:
    "https://aircrexlandingpageprod.azureedge.net/static/assets/animation/reduce-cart.json",
  REFER_FRIEND:
    "https://aircrexlandingpageprod.azureedge.net/static/assets/animation/refer_friend.json",
  SAVE_AS_YOU_SPEND:
    "https://aircrexlandingpageprod.azureedge.net/static/assets/animation/save_as_you_spend.json",
  SUBSCRIBE_EMAIL:
    "https://aircrexlandingpageprod.azureedge.net/static/assets/animation/subscribe-email.json",
  TRUSTED_BY:
    "https://aircrexlandingpageprod.azureedge.net/static/assets/animation/trusted-by.json",
  WORK_ECOMMERCE:
    "https://aircrexlandingpageprod.azureedge.net/static/assets/animation/we-work-ecommerce.json",
  XERO_BANNER:
    "https://aircrexlandingpageprod.azureedge.net/static/assets/animation/xero_banner.json",
  XERO_SLIDER_ONE:
    "https://aircrexlandingpageprod.azureedge.net/static/assets/animation/xero_slider_one.json",
  XERO_SLIDER_TWO:
    "https://aircrexlandingpageprod.azureedge.net/static/assets/animation/xero_slider_two.json",
  XERO_SLIDER_THREE:
    "https://aircrexlandingpageprod.azureedge.net/static/assets/animation/xero_slider_three.json",
};

export const DOT_LOTTIE_URLS: any = {
  ALTERNATIVE:
    "https://aircrexlandingpageprod.azureedge.net/static/assets/animation/alternative.lottie",
  AUTHORISATION_REQUEST:
    "https://aircrexlandingpageprod.azureedge.net/static/assets/animation/authorisation_request.lottie",
  BENEFITS:
    "https://aircrexlandingpageprod.azureedge.net/static/assets/animation/benefits.lottie",
  BUY_TO_EARN:
    "https://aircrexlandingpageprod.azureedge.net/static/assets/animation/buy-to-earn-tab.lottie",
  CARD_TAB:
    "https://aircrexlandingpageprod.azureedge.net/static/assets/animation/card-tab.lottie",
  CASHBACK:
    "https://aircrexlandingpageprod.azureedge.net/static/assets/animation/cashback.lottie",
  DEVELOP_TEAM:
    "https://aircrexlandingpageprod.azureedge.net/static/assets/animation/develop-team.lottie",
  DISCOVER:
    "https://aircrexlandingpageprod.azureedge.net/static/assets/animation/discover.lottie",
  EASY_BUDGETING_CUT:
    "https://aircrexlandingpageprod.azureedge.net/static/assets/animation/easy-budgeting-cut.lottie",
  FAQ: "https://aircrexlandingpageprod.azureedge.net/static/assets/animation/faq.lottie",
  FIND_US_ONE:
    "https://aircrexlandingpageprod.azureedge.net/static/assets/animation/find_us_one.lottie",
  FIND_US_TWO:
    "https://aircrexlandingpageprod.azureedge.net/static/assets/animation/find_us_two.lottie",
  GET_PAID:
    "https://aircrexlandingpageprod.azureedge.net/static/assets/animation/get_paid.lottie",
  INCREASE_BASKET:
    "https://aircrexlandingpageprod.azureedge.net/static/assets/animation/increase-basket.lottie",
  INDUSTRIES_BILLER:
    "https://aircrexlandingpageprod.azureedge.net/static/assets/animation/industries_biller.lottie",
  INDUSTRIES_E_COMMERCE:
    "https://aircrexlandingpageprod.azureedge.net/static/assets/animation/industries_e-commerce.lottie",
  INDUSTRIES_GAMING_AND_CRYPTO:
    "https://aircrexlandingpageprod.azureedge.net/static/assets/animation/industries_gaming_and_crypto.lottie",
  INDUSTRIES_SME:
    "https://aircrexlandingpageprod.azureedge.net/static/assets/animation/industries_sme.lottie",
  INDUSTRIES_TELCO:
    "https://aircrexlandingpageprod.azureedge.net/static/assets/animation/industries_telco.lottie",
  INSTANT_CUSTOMER:
    "https://aircrexlandingpageprod.azureedge.net/static/assets/animation/instant_customer.lottie",
  // INSTANT_FLASH:
  //   "https://aircrexlandingpageprod.azureedge.net/static/assets/animation/instant-flash.lottie",
  MARKETING_ASSETS:
    "https://aircrexlandingpageprod.azureedge.net/static/assets/animation/marketing-assets.lottie",
  MERCHANT_DISCOVER:
    "https://aircrexlandingpageprod.azureedge.net/static/assets/animation/merchant-discover.lottie",
  ONE_CLICK_CHECKOUT:
    "https://aircrexlandingpageprod.azureedge.net/static/assets/animation/one_click_checkout.lottie",
  // PAGE_LOADING:
  //   "https://aircrexlandingpageprod.azureedge.net/static/assets/animation/page_loading.lottie",
  PAY_AND_GET_PAID:
    "https://aircrexlandingpageprod.azureedge.net/static/assets/animation/pay_and_get_paid.lottie",
  PAY_WITH_PAYTO:
    "https://aircrexlandingpageprod.azureedge.net/static/assets/animation/pay_with_payto.lottie",
  PAYID_TAB:
    "https://aircrexlandingpageprod.azureedge.net/static/assets/animation/payid-tab.lottie",
  PAYTO_INSTANT:
    "https://aircrexlandingpageprod.azureedge.net/static/assets/animation/payto_instant_customer.lottie",
  PAYTO_TAB:
    "https://aircrexlandingpageprod.azureedge.net/static/assets/animation/payto-tab.lottie",
  REACH_NEW_CUSTOMER:
    "https://aircrexlandingpageprod.azureedge.net/static/assets/animation/reach-new-customer.lottie",
  REAL_TIME:
    "https://aircrexlandingpageprod.azureedge.net/static/assets/animation/real-time.lottie",
  REDUCE_FRAUD:
    "https://aircrexlandingpageprod.azureedge.net/static/assets/animation/reduce_fraud.lottie",
  REDUCE_CART:
    "https://aircrexlandingpageprod.azureedge.net/static/assets/animation/reduce-cart.lottie",
  REFER_FRIEND:
    "https://aircrexlandingpageprod.azureedge.net/static/assets/animation/refer_friend.lottie",
  SAVE_AS_YOU_SPEND:
    "https://aircrexlandingpageprod.azureedge.net/static/assets/animation/save_as_you_spend.lottie",
  SUBSCRIBE_EMAIL:
    "https://aircrexlandingpageprod.azureedge.net/static/assets/animation/subscribe-email.lottie",
  TRUSTED_BY:
    "https://aircrexlandingpageprod.azureedge.net/static/assets/animation/trusted-by.lottie",
  WORK_ECOMMERCE:
    "https://aircrexlandingpageprod.azureedge.net/static/assets/animation/we-work-ecommerce.lottie",
  XERO_BANNER:
    "https://aircrexlandingpageprod.azureedge.net/static/assets/animation/xero_banner.lottie",
  XERO_SLIDER_ONE:
    "https://aircrexlandingpageprod.azureedge.net/static/assets/animation/xero_slider_one.lottie",
  XERO_SLIDER_TWO:
    "https://aircrexlandingpageprod.azureedge.net/static/assets/animation/xero_slider_two.lottie",
  XERO_SLIDER_THREE:
    "https://aircrexlandingpageprod.azureedge.net/static/assets/animation/xero_slider_three.lottie",
};

export const TCFILE: string =
  "https://aircrexlandingpageprod.azureedge.net/static/assets/docs/T&Cs.pdf";
