import { memo } from "react";

import { NextImage } from "Components/Lib/Next";
import { CAKE_CREATION_URL } from "Constants/URL.Constant";

import cakeCreationCEO from "Assets/Images/Others/cake_creation_ceo.png";

const ImageComponent = () => {
  return (
    <div className="relative flex flex-1 flex-col items-center justify-center">
      <a href={CAKE_CREATION_URL} target="_blank" rel="noreferrer">
        <div className="hidden lg:absolute lg:top-[35%] lg:left-[-10px] lg:block">
          <img src="/Assets/Svg/play.svg" width={100} height={100} alt="play" />
        </div>
        <div className="mb-4 hidden md:block lg:hidden">
          <img src="/Assets/Svg/play.svg" width={100} height={100} alt="play" />
        </div>
        <div className="mb-4 block md:hidden">
          <img src="/Assets/Svg/play.svg" width={60} height={60} alt="play" />
        </div>
      </a>

      <NextImage
        src={cakeCreationCEO}
        alt="cake-creation-ceo"
        width={625}
        height={900}
      />
    </div>
  );
};

export default memo(ImageComponent);
